.swal2-container {
    z-index: 10000;
}
.swal-overlay--show-modal .swal-modal{
    z-index: 1000000;
}
/* .exel>.form-control.form-control-solid{
    border-radius: 0px !important;
}
.exel>.form-select.form-select-solid{
    border-radius: 0px !important;
} */

.swal-overlay, .swal-overlay--show-modal{
    z-index: 1000000;
}

.search-wrapper{
    border: none !important;
    border-radius: 10px !important;
    background-color:  #F8F6F1;
}
.chip,.highlightOption,.option:hover{
    background-color: rgb(1 13 88) !important;
}
.optionListContainer{
   position: relative !important;
}
