.swiper-pagination-bullet{
    width: 12px !important;
    height: 12px !important;
    background-color: #FFE9A0;
    top:0px;
}
.swiper-pagination-bullet-active{
  
    background-color: #f06445 !important;
}
.swiper-pagination{
  bottom: 7px !important;
}
.swiper {
    width: 100%;
    height: auto;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  }
  
  /* .swiper .swiper-slide {
    height: 300px;
    line-height: 300px;
  }
  
  .swiper .swiper-slide:nth-child(2n) {
    height: 500px;
    line-height: 500px;
  } */