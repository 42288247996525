.busyDay {
  background-color: #eeb9ad !important;
  color: white;
}
div::-webkit-scrollbar {
  width: 10px;
}
.modal-90w {
  width: 90% !important;
  min-width: 90% !important;
}
