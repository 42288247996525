.swal-modal {
    margin-top: 200px;
    width: 400px;
    height: 260px;
    font-size: small;
  }
  .swal-title {
    font-size: medium;
  }
  .swal-text {
    font-size: 14px;
  }
  .swal-icon {
      font-size: 10px;
  }
  .swal-footer{
      display: flex;
      justify-content: center;
      width: 65%;
      margin: 0px auto;
      padding: auto;
      
  }
  .swal-button {
    font-size: 10px;
    width: 100px;
  }
  .swal-button--cancel{
    background-color: black;
    border: none !important;
    color: #fff;
  }
  
  .modal {
    position: absolute;
    top: 250px;
    right: 100px;
    bottom: 0;
    left: 0;
    z-index: 10040;
    overflow: auto;
    overflow-y: auto;
  } 
  .row{
    overflow: hidden; 
}

[class*="col-"]{

}