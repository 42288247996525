.swal-modal {
  margin-bottom: 400px;
  width: auto;
  max-width: 350px;
  height: auto !important;
  max-height: 450px;
  
  font-size: small;
}
.swal-title {
  font-size: small;
}
.swal-text {
  font-size: 12px;
}
.swal-button {
  font-size: 10px;
  width: 100px;
}
.swal-button--confirm {
  margin-left: 10px;
  margin-right: 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
}

.swal-button--cancel {
  background-color: black;
  color: white;
  border: none !important;
  border-radius: 10px;
}
.modal {
  position: absolute;
  top: 250px;
  right: 100px;
  bottom: 0;
  left: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
} 
