.events {
  display: flex;
  flex-wrap: nowrap;
  height: 250px;
  width: auto;
  overflow-x: scroll;
}
.events .event-list:nth-child(odd) {
  align-items: flex-start;
}
.events .event-list:nth-child(even) {
  align-items: flex-end;
}
.events::before {
  content: '';
  position: absolute;
  height: 3px;
  width: 96%;
  background: #dee2e6;
  top: 50%;
  z-index: 1000;
}
.events .event-list {
  height: 100%;
  width: 150px;
  min-width: 130px;
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.events .event-list:nth-child(odd):before {
  content: '';
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 60px;
}
.events .event-list:nth-child(even):before {
  content: '';
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 150px;
  z-index: -1;
}

.events .event-list:after {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;

  /* transform: rotate(-90deg); */
  background: #dee2e6;
  top: 104px;
}
.event-list {
  width: 150px;
}

.events .event-list :nth-child(odd) :before {
  top: 100px;
}
.events .event-list :nth-child(even) :before {
  flex-direction: column-reverse;
  bottom: 100px;
}
/* .start{
    top: 50px;
} */

.events .event-list .event-date {
  position: absolute;
  top: 51%;
  left: 20px;
  width: 100px;
  margin: 0;
  border-radius: 4px;
}

/* @media (min-width: 1140px) {
    .hori-timeline{
        max-height:200px;
        overflow:auto ;
        padding-top: 10px;
    
    }
    .events {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        max-width: 100vw;
        border-top: 3px solid #e9ecef;
    }
    .events .event-list {
        display: inline-block;
        width: 250px;
        padding-top: 65px;
    }
    .events .event-list .event-date {
        top: 12px;
    }
} */
.bg-soft-primary {
  background-color: rgba(64, 144, 203, 0.3) !important;
}
.bg-soft-success {
  background-color: rgba(71, 189, 154, 0.3) !important;
}
.bg-soft-danger {
  background-color: rgba(231, 76, 94, 0.3) !important;
}
.bg-soft-warning {
  background-color: rgba(249, 213, 112, 0.3) !important;
}
.card {
  border: none;
  margin-bottom: 24px;
  -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
  box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
}
